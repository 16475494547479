/*
 * Design by Adnet Communications Inc.
 * Implementation by Adnet Communications Inc.
 * (c) 2016
 */

/* general */
* {box-sizing: border-box;}
html {-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;}
a {color: #c7a134; outline: none; text-decoration: none; transition: color 0.3s;}
a:hover {color: inherit; text-decoration: none;}
input {border-radius: 0;}
em {color: inherit;}
h2, h3 {font-family: 'AvenirLTStd45Book';}
h3.section-title {margin-top: 25px;}

/* uikit specific */
[class*=uk-icon-] {vertical-align: initial;}
p {margin: 25px 0;}
.uk-table td, .uk-table th {border: 1px solid #ddd;}
.uk-table th {background: #c7a134; color: #fff;}
.uk-table.uk-minwidth td {min-width: 120px;}
.uk-panel > p:first-child {margin-top: 0;}
figure.uk-overlay {border: 1px solid #666;}
.uk-thumbnail {border: none;}
.uk-list-style {list-style-type: disc; padding: 0 0 0 16px;}
.uk-list-style li {margin: 0 0 15px;}
.uk-list-style > li:after, .uk-list > li:before {display: initial;}
.uk-panel-box h3.section-title {margin-top: 10px;}
table.stock-data td {width: 25%;}
.uk-modal-caption {bottom: 0; position: relative; white-space: normal;}

/* fonts */
@font-face {
    font-family: 'Avenir LT Std 55 Roman';
    src: url('//cdn.adnetcms.com/lib/webfonts/avenir/light/AvenirLTStd-Light.eot');
    src: url('//cdn.adnetcms.com/lib/webfonts/avenir/light/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/light/AvenirLTStd-Light.woff') format('woff'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/light/AvenirLTStd-Light.ttf') format('truetype'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/light/AvenirLTStd-Light.svg#AvenirLTStd55Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirMedium';
    src: url('//cdn.adnetcms.com/lib/webfonts/avenir/medium/avenm___-webfont.eot?') format('eot'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/medium/avenm___-webfont.woff') format('woff'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/medium/avenm___-webfont.ttf') format('truetype'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/medium/avenm___-webfont.svg#webfontA2uRWDTC') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirLTStd45Book';
    src: url('//cdn.adnetcms.com/lib/webfonts/avenir/book/aveniravenirltstd-book-webfont.eot');
    src: url('//cdn.adnetcms.com/lib/webfonts/avenir/book/avenirltstd-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/book/avenirltstd-book-webfont.woff') format('woff'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/book/avenirltstd-book-webfont.ttf') format('truetype'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/book/avenirltstd-book-webfont.svg#AvenirLTStd45Book') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirHeavy';
    src: url('//cdn.adnetcms.com/lib/webfonts/avenir/heavy/avenh___-webfont.eot?') format('eot'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/heavy/avenh___-webfont.woff') format('woff'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/heavy/avenh___-webfont.ttf') format('truetype'),
         url('//cdn.adnetcms.com/lib/webfonts/avenir/heavy/avenh___-webfont.svg#webfont3lyybxHi') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* all pages */
body {background: #fff; color: #636363; font-family: arial, sans-serif; font-size: 14px; line-height: 20px; margin: 0;}
header {height: 150px; position: relative; z-index: 101;}
.wrapper {margin: auto; padding: 0 26px; position: relative; width: 1360px;}
.logo {left: 60px; position: absolute; transition: all 0.2s ease-in-out; top: 38px;}
.logo img {display: block;}
.main-nav {margin: 0; padding: 0; position: absolute; right: 50px; top: 78px;}
.main-nav li {display: inline-block; position: relative;}
.main-nav li a {display: block;}
.main-nav > li > a {color: #fff; font-family: 'AvenirMedium'; font-size: 18px; line-height: 48px; padding: 0 14px; text-align: center;}
.main-nav > li:hover > a {border-bottom: 3px solid #fff;}
.main-nav > li.uk-active > a {border-bottom: 3px solid #fff;}
.main-nav li ul {background: #244d83; display: none; left: 0; padding: 10px; position: absolute; top: 100%;}
.main-nav li:hover > ul {display: inherit;}
.main-nav > li:last-child ul {left: auto; right: 0;}
.main-nav > li:last-child ul li a {text-align: right;}
.main-nav li ul li {list-style: none; padding: 6px 0;}
.main-nav li ul li a {color: #fff; font-size: 16px; height: auto; line-height: 18px; padding: 0 14px; text-align: left; width: 160px;}
.main-nav li ul li a:hover {color: #c7a134;}
.main-nav li ul li ul {left: 100%; top: 0;}
.stockinfo {display: flex; position: absolute; right: 66px; top: 40px;}
.stockinfo ul {list-style-type: none; margin: 0 0 0 20px; padding: 0;}
.stockinfo ul li {color: #fff; display: inline-block; font-family: 'AvenirMedium';}
.subpage .main-nav > li > a {color: #333;}
.subpage .main-nav > li:hover > a {border-bottom: 3px solid #c7a134;}
.subpage .main-nav > li.uk-active > a {border-bottom: 3px solid #c7a134;}
.subpage .stockinfo ul li {color: #333;}

#slider {margin: -150px 0 0; position: relative; width: 100%;}
#slider .slide {height: 800px; width: 100%;}
#slider * {transition: all 0.3s;}
.stock-info {color: #f0f0f0; display: none; font-family: 'AvenirMedium'; font-size: 20px; left: 0; line-height: 50px; position: absolute; text-align: center; top: 215px; width: 100%;}
.subpage .stock-info {display: none;}
.tagline {left: 0; position: absolute; text-align: center; top: 270px; width: 100%;}
.tagline h1 {color: #f0f0f0; font-family: 'Avenir LT Std 55 Roman'; font-size: 49px; font-weight: 300; line-height: 59px; padding: 0 100px; text-transform: uppercase;}
.tagline h2 {color: #f0f0f0; font-family: 'AvenirLTStd45Book'; font-size: 32px; line-height: 46px; padding: 0 80px;}
.tagline p {color: #f0f0f0; font-family: 'AvenirLTStd45Book'; font-size: 55px; line-height: 68px; margin: 0; padding: 0 100px;}
.tagline p.small {font-size: 40px; line-height: 46px;}
.tagline .more {background: rgba(255,255,255,.3); border: 3px solid #fff; border-radius: 40px; color: #fff; display: inline-block; font-family: 'AvenirMedium'; font-size: 17px; line-height: 40px; margin: 100px 0 0; padding: 0 34px; text-transform: uppercase;}
.tagline .more:hover {background: rgba(199,161,52,.3);}
.subpage .tagline {top: 50%; transform: translateY(-50%);}
.subpage .tagline h1 {font-size: 42px; line-height: 52px;}
.banner {height: 380px;}
.banner .wrapper {height: 100%;}

.row-1 .col {float: left; width: 50%;}
.row-1 .col-2 {padding: 40px 46px 0;}
.row-1 .col-2 h3 {color: #c7a134; font-size: 18px; line-height: 24px; text-transform: uppercase;}
.row-1 .col-2 h2 {color: #454545; font-size: 28px; line-height: 36px; margin: 0 0 25px;}
.row-1 .col-2 p {color: #636363; font-size: 15px; line-height: 22px;}
.row-1 .col-1 {background: #dadada;}
.row-1 .map-home {background: #dadada; height: 100%;}
.row-2 {background: #edd4a8; /* Old browsers */ 
	background: -moz-linear-gradient(top,  #edd4a8 20%, #ffffff 80%); /* FF3.6-15 */ 
	background: -webkit-linear-gradient(top,  #edd4a8 20%,#ffffff 80%); /* Chrome10-25,Safari5.1-6 */ 
	background: linear-gradient(to bottom,  #edd4a8 20%,#ffffff 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	margin: 0 0 0 -60px; padding: 50px 0 80px;}
.row-2 h2 {text-transform: uppercase;}
.row-2 .col {float: left; padding: 0 0 0 60px; width: 33.3%;}
.row-2 .col-1 ul {list-style-type: none; margin: 0; padding: 0;}
.row-2 .col-1 ul li {margin: 0 0 18px;}
.row-2 .col-1 ul li a {color: #636363; display: block; font-size: 14px; line-height: 17px;}
.row-2 .col-1 ul li a span.date {color: #000; display: block; font-size: 17px; font-weight: bold; line-height: 23px; margin: 0 0 4px;}
.row-2 .subscribe {background: #000; border-radius: 28px; color: #d8d8d8; display: inline-block; font-size: 17px; line-height: 44px; padding: 0 38px;}
.row-2 .subscribe:hover {color: #c7a134;}
.row-2 .col-2 p {color: #393939; font-size: 17px; line-height: 30px;}
.social {margin: 25px 0 0;}
.social h2 {display: inline-block; margin-bottom: 0 !important;}
.social a {display: inline-block; font-size: 24px; line-height: 30px; margin: 0 0 0 10px;}
.stock-table {border-collapse: collapse; color: #464646; width: 90%;}
.stock-table th {font-size: 20px; padding: 5px 0 25px; text-align: left; text-transform: uppercase;}
.stock-table td {font-size: 14px; padding: 3px 0; text-transform: uppercase;}
.stock-table th:last-child, .stock-table td:last-child {text-align: right;}
.features .col {border-right: 1px solid #fff; float: left; width: 25%;}
.features .col:last-child {border: none;}
.features .col a {background: #000; display: block; overflow: hidden; position: relative;}
.features .col a:hover img {opacity: 1; transform: scale(1.1);}
.features .col img {opacity: .6; transition: all .3s;}
.features .col .title {color: #e8e8e8; display: block; font-family: 'AvenirMedium'; font-size: 26px; left: 0; line-height: 1; position: absolute; text-align: center; text-shadow: 0 0 10px rgba(0,0,0,.6); top: 50%; transform: translateY(-50%); width: 100%;}
.main-sub {margin: 35px auto;}
.side-col {float: left; width: 20%;}
.side-col h2 {color: #1d1d1d; font-size: 25px; line-height: 32px; margin: 0 0 26px; text-transform: uppercase;}
.side-col ul {list-style-type: none; margin: 0; padding: 0;}
.side-col ul li {margin: 0 0 6px; position: relative;}
.side-col ul li a {color: #c7a134; display: block; font-size: 17px; font-weight: bold; line-height: 23px; padding: 3px 12px;}
.side-col ul li.uk-active a {background: #c7a134; color: #fff;}
.side-col ul li.uk-active a:after {content: '\f0da'; font-family: 'FontAwesome'; font-size: 14px; font-weight: normal; position: absolute; right: 12px;}
.side-col .subscribe {background: #000; border-radius: 28px; color: #d8d8d8; display: inline-block; font-size: 17px; line-height: 44px; margin: 20px 0 0; padding: 0 38px;}
.side-col .subscribe:hover {color: #c7a134;}
.main-col {float: right; min-height: 300px; width: 75%;}
.main-col h2.header {font-family: 'AvenirHeavy'; margin: 0 0 26px;}
footer {background: #393939; padding: 40px 0;}
footer .col-1 {float: left;}
footer .col-2 {float: right;}
footer ul {display: flex; list-style-type: none; margin: 0; padding: 0;}
footer ul.sub {display: block;}
footer li {margin: 0 4% 0 0; width: 120px;}
footer li a {color: #7c7c7c; display: block; font-size: 12px;}
footer li a:hover {color: #c7a134;}
footer .col-1 > ul > li > a {margin: 0 0 15px; text-transform: uppercase;}

/* mobile-nav */
.mobile-nav-btn {display: none; height: 50px; position: absolute; right: 30px; top: 38px; width: 50px;}
.mobile-nav-btn span {background: #fff; display: inline-block; height: 3px; left: 11px; position: absolute; text-indent: -9999px; top: 22px; width: 25px;}
.subpage .mobile-nav-btn span {background: #333;}
.mobile-nav-btn span:before, .mobile-nav-btn span:after {background-color: inherit; content: ''; height: 100%; left: 0; position: absolute; transition: 0.3s all; width: 100%;}
.mobile-nav-btn span:before {bottom: 9px;}
.mobile-nav-btn span:after {top: 9px;}
.mobile-nav-btn.is-clicked span {background: none;}
.mobile-nav-btn.is-clicked span:before {background: #333; bottom: 0; transform: rotate(45deg);}
.mobile-nav-btn.is-clicked span:after {background: #333; top: 0; transform: rotate(-45deg);}
.mobile-nav {background: #eee; border-left: 1px solid #ccc; box-shadow: 0 0 10px rgba(0,0,0,.3); height: 100%; overflow-y: auto; position: fixed; right: 0; top: 0; visibility: hidden; width: 260px; z-index: 150;
	transition: transform .3s 0s, visibility 0s .3s; transform: translateX(260px);
	-webkit-transform: translateZ(0); -webkit-backface-visibility: hidden; -webkit-transition: -webkit-transform .3s 0s, visibility 0s .3s; -moz-transition: -moz-transform .3s 0s, visibility 0s .3s; -webkit-transform: translateX(260px); -moz-transform: translateX(260px); -ms-transform: translateX(260px); -o-transform: translateX(260px);}
.mobile-nav.mobile-nav-is-open {transform: translateX(0); visibility: visible; transition: transform .3s 0s, visibility 0s 0s;
	-webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0);
	-webkit-transition: -webkit-transform .3s 0s, visibility 0s 0s; -moz-transition: -moz-transform .3s 0s, visibility 0s 0s; -webkit-overflow-scrolling: touch;}
.mobile-nav ul {list-style-type: none; margin: 0; padding: 0;}
.mobile-nav ul.nav li {box-shadow: 0 1px #ccc inset;}
.mobile-nav ul.nav li a {display: block; font-size: 16px; line-height: 50px; padding: 0 20px;}
.mobile-nav ul.nav li.has-sub a:after {content: '\f105'; float: right; font-family: 'FontAwesome'; font-size: 20px;}
.mobile-nav ul.nav li.has-sub.sub-open > a {color: #000;}
.mobile-nav ul.nav li.has-sub.sub-open a:after {content: '\f107';}
.mobile-nav ul.nav li.has-sub ul.sub li a:after {content: '';}
.mobile-nav ul.sub {display: none;}
.mobile-nav ul.sub li {padding: 9px 10px;}
.mobile-nav ul.sub li a {font-size: 14px; line-height: 20px;}
.mobile-nav ul.top {color: #525252; font-size: 14px; line-height: 40px; overflow: hidden; padding: 0 20px;}
.mobile-nav ul.top-1 {box-shadow: 0 1px #ccc;}
.mobile-nav ul.top li {float: left; margin: 0 12px 0 0;}
.mobile-nav ul.top li.close {margin: 0 8px 0 0;}
.mobile-nav ul.top li.close a {color: inherit;}
.mobile-nav ul.top li.close span:before {content: '\f00d'; font-family: 'FontAwesome';}
.mobile-nav ul.top li.price {float: right; margin: 0;}
.mobile-nav ul.top li a {display: inline-block; margin: 0 12px 0 0;}
.mobile-nav ul.copyright {box-shadow: 0 1px #ccc inset; color: #525252; font-size: 14px; line-height: 20px; padding: 14px 20px; text-align: center;}

/* Large desktop */
@media (max-width: 1360px) {
.wrapper {padding: 0 60px; width: 100%;}
.row-1 {padding: 0;}
.row-2 .col-2 p {font-size: 14px; line-height: 20px;}
.features .wrapper {padding: 0;}
}

/* Desktop and smaller */
@media (max-width: 960px) {
header {height: 120px;}
.logo {left: 25px; top: 25px;}
.logo img {width: 200px;}
.main-nav {display: none;}
.stockinfo {display: none;}
.mobile-nav-btn {display: block;}
#slider .slide {height: 500px;}
.stock-info {font-size: 16px; top: 150px;}
.tagline {top: 200px;}
.tagline h1 {font-size: 30px; line-height: 40px; padding: 0 100px;}
.tagline h2 {font-size: 26px; line-height: 32px;}
.tagline p.small {font-size: 28px; line-height: 32px;}
.tagline .more {border: 2px solid #fff; font-size: 14px; line-height: 28px; margin: 50px 0 0; padding: 0 22px;}
.subpage .tagline h1 {font-size: 30px; line-height: 40px;}
.banner {height: 260px;}
.row-1 .col {float: none; width: 100%;}
.map-home img {display: block; margin: auto;}
.row-1 .col-2 {padding: 40px 25px 20px;}
.row-1 .col-2 h2 {font-size: 22px; line-height: 30px;}
.row-1 .col-2 h3 {font-size: 16px; line-height: 22px;}
.row-1 .col-2 p {font-size: 14px; line-height: 20px;}
.row-2 {margin: 0; padding: 40px 0 20px;}
.row-2 .wrapper {padding: 0 25px;}
.row-2 .col {float: none; margin: 0 0 25px; padding: 0; width: 100%;}
.stock-table {width: 50%;}
.features .col {border-bottom: 1px solid #000; border-right: none; width: 100%;}
.features .col img {height: 100px;}
.features .col a {background: #244d83;}
.features .col .title {font-size: 20px; padding: 0 0 0 140px; text-shadow: none;}
.main-sub {padding: 0 25px;}
.main-col {float: none; margin: 0 0 40px; width: 100%;}
.side-col {float: none; width: 300px;}
footer .col {float: none; text-align: center; width: 100%;}
footer .col-1 > ul > li > a {margin: 0;}
footer ul {display: block; margin: 0 0 15px; text-align: center;}
footer li {display: inline-block; margin: 0 5px; width: auto;}
footer ul.sub {display: none;}
}

/* Phone landscape and smaller*/
@media (max-width: 767px) {
.tagline h1, .tagline h2, .tagline p {padding: 0 40px;}
.tagline p.small {font-size: 22px; line-height: 26px;}
}

/* Phone portrait and smaller*/
@media (max-width: 480px) {
#slider .slide {height: 300px;}
.banner {height: 200px;}
.stock-info {font-size: 12px; line-height: 20px; top: 128px;}
.tagline {top: 160px;}
.tagline h1 {font-size: 18px; line-height: 24px; padding: 0 10px;}
.tagline h2 {font-size: 18px; line-height: 22px; padding: 0 10px;}
.tagline p {padding: 0 10px;}
.tagline p.small {font-size: 18px; line-height: 24px;}
.tagline .more {font-size: 12px; line-height: 20px; margin: 0; padding: 0 16px;}
.subpage .tagline h1 {font-size: 18px; line-height: 24px;}
.stock-table {width: 100%;}
.features .col img {height: 80px;}
.features .col .title {font-size: 16px; padding: 0 0 0 120px;}
.side-col {width: 100%;}
}
